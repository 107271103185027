<template>
  <div>
    <h2>PEGAWAI NEGERI SIPIL</h2>

    <v-divider class="mt-3 mb-7"></v-divider>

    <div>
      <v-card class="px-3 pt-4" outlined>
        <v-col cols="12">
          <v-row>
            <v-avatar size="100" class="mx-3">
              <v-img src="@/assets/gambar/no_pic_male.png"></v-img>
            </v-avatar>

            <v-col cols="10" md="6" class="mt-5">
              <v-text-field
                v-model="carivalue"
                label="Masukan Nama / NIP"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-btn icon color="primary" class="ml-2 my-auto" @click="cari()">
              <v-icon>mdi-account-search</v-icon>
            </v-btn>
          </v-row>
        </v-col>

        <v-divider class="mb-3"></v-divider>

        <v-col cols="12">
          <v-row>
            <v-text-field
              label="Instansi"
              v-model="instansi"
              outlined
              dense
              filled
              readonly
              class="mr-3"
            ></v-text-field>

            <v-text-field
              label="Unit Kerja"
              v-model="unitkerja"
              outlined
              dense
              filled
              readonly
            ></v-text-field>
          </v-row>
        </v-col>
      </v-card>
    </div>

    <div class="mt-10">
      <div v-if="pns">
        <router-view />
      </div>

      <v-alert v-model="alertG" type="error" v-else>
        Pefawai negeri sipil dgn NIP
        <strong>{{ $route.params.id }}</strong> tidak ditemukan di database
        kepegawaian
      </v-alert>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  data: () => ({
    token: Cookie.get("token"),
    pns: false,
    alertG: false,

    carivalue: "",
    instansi: "",
    unitkerja: "",
  }),

  mounted() {
    if (this.$route.params.id) {
      this.carivalue = this.$route.params.id;
      this.cari();
    } else {
      console.log("kosong");
    }
  },

  methods: {
    cari() {
      const url =
        process.env.VUE_APP_ASN + "aparatur/cari/nip/" + this.carivalue;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.mapData.length > 0) {
              this.$router.push("/pns/" + this.carivalue).catch(() => {});
              
              this.pns = true;
              this.alertG = false;
              this.instansi = res.data.mapData[0].unorNama;
              this.unitkerja = res.data.mapData[0].unorNama;
            } else {
              this.pns = false;
              this.alertG = true;
            }
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>